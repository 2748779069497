<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else>
        <div class="stscntr_comparison_hdtohd" v-if="h2h.h2hMatches.length">
            <h2> Recent Head to Head Results </h2>
            <div class="ft_lc_list">
                <div class="ft_lc_row" v-for="match in h2h.h2hMatches" :key="match.id">
                    <!--<td>PR 17</td>-->
                    <div class="ft_lc_time ft_lc_h2htime">
                      <span>
                        {{match.datetime | formatDate('DD-MMM-YYYY') }}
                      </span>
                    </div>
                    <div class="ft_lc_rslt">
                        <a href="javascript:;">
                            <div class="ft_lc_name">
                                <span class="ft_lc_hm_tname" v-if="match.home_is_home">{{match.team1_name}}</span>
                                <span class="ft_lc_hm_tname" v-else>{{match.team2_name}}</span>
                                <img v-if="match.home_is_home" :src="$storageURL+'images/rugby/'+match.team1_id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="match.team1_name+' Team Logo'" class="ft_lc_hm_pic">
                                <img v-else :src="$storageURL+'images/rugby/'+match.team2_id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="match.team2_name+' Team Logo'" class="ft_lc_hm_pic">
                            </div>
                            <div class="ft_lc_score">
                                <h6 class="ft_lc_score_ft">
                                    {{$formatFootballScore(match.ft)}}
                                </h6>
                            </div>
                            <div class="ft_lc_name">
                                <img v-if="match.away_is_home" :src="$storageURL+'images/rugby/'+match.team1_id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="match.team1_name+' Team Logo'" class="ft_lc_awy_pic">
                                <img v-else :src="$storageURL+'images/rugby/'+match.team2_id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="match.team2_name+' Team Logo'" class="ft_lc_awy_pic">
                                <span class="ft_lc_awy_tname" v-if="match.away_is_home">{{match.team1_name}}</span>
                                <span class="ft_lc_awy_tname" v-else>{{match.team2_name}}</span>
                            </div>
                        </a>
                    </div>
                    <div class="ft_lc_fav"></div>
                </div>
            </div>
        </div>

        <div class="stscntr_comparison_hdtohd" v-if="h2h.home_team_matches.length">
            <h2>{{$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, true).name}} Last 5 Matches</h2>
            <div class="ft_lc_list">
                <div class="ft_lc_row" v-for="match in h2h.home_team_matches" :key="match.id">
                    <!--<td>PR 17</td>-->
                    <div class="ft_lc_time ft_lc_h2htime">
                      <span>
                        {{match.datetime | formatDate('DD-MMM-YYYY') }}
                      </span>
                    </div>
                    <div class="ft_lc_rslt">
                        <a href="javascript:;">
                            <div class="ft_lc_name">
                                <span class="ft_lc_hm_tname">{{$filterTeam(match.rugbyMatchCompetitors, true).team.name}}</span>
                                <img :src="$storageURL+'images/rugby/'+$filterTeam(match.rugbyMatchCompetitors, true).team.id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="$filterTeam(match.rugbyMatchCompetitors, true).team.name+' Team Logo'" class="ft_lc_hm_pic">
                            </div>
                            <div class="ft_lc_score">
                                <h6 class="ft_lc_score_ft">
                                    {{match.ft}}
                                </h6>
                            </div>
                            <div class="ft_lc_name">
                                <img :src="$storageURL+'images/rugby/'+$filterTeam(match.rugbyMatchCompetitors, false).team.id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="$filterTeam(match.rugbyMatchCompetitors, false).team.name+' Team Logo'" class="ft_lc_awy_pic">
                                <span class="ft_lc_awy_tname">{{$filterTeam(match.rugbyMatchCompetitors, false).team.name}}</span>

                            </div>
                        </a>
                    </div>
                    <div class="ft_lc_fav"></div>
                </div>
            </div>
        </div>
        <div class="stscntr_comparison_hdtohd" v-if="h2h.away_team_matches.length">
            <h2>{{$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, false).name}} Last 5 Matches</h2>
            <div class="ft_lc_list">
                <div class="ft_lc_row" v-for="match in h2h.away_team_matches" :key="match.id">
                    <!--<td>PR 17</td>-->
                    <div class="ft_lc_time ft_lc_h2htime">
                      <span>
                        {{match.datetime | formatDate('DD-MMM-YYYY') }}
                      </span>
                    </div>
                    <div class="ft_lc_rslt">
                        <a href="javascript:;">
                            <div class="ft_lc_name">
                                <span class="ft_lc_hm_tname">{{$filterTeam(match.rugbyMatchCompetitors, true).team.name}}</span>
                                <img :src="$storageURL+'images/rugby/'+$filterTeam(match.rugbyMatchCompetitors, true).team.id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="$filterTeam(match.rugbyMatchCompetitors, true).team.name+' Team Logo'" class="ft_lc_hm_pic">
                            </div>
                            <div class="ft_lc_score">
                                <h6 class="ft_lc_score_ft">
                                    {{match.ft}}
                                </h6>
                            </div>
                            <div class="ft_lc_name">
                                <img :src="$storageURL+'images/rugby/'+$filterTeam(match.rugbyMatchCompetitors, false).team.id+'_90x90.webp'" :onerror="$teamDefaultLogo()" :alt="$filterTeam(match.rugbyMatchCompetitors, false).team.name+' Team Logo'" class="ft_lc_awy_pic">
                                <span class="ft_lc_awy_tname">{{$filterTeam(match.rugbyMatchCompetitors, false).team.name}}</span>

                            </div>
                        </a>
                    </div>
                    <div class="ft_lc_fav"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "MatchDetailTeamhistory",
        data() {
            return {
                h2h: [],
            };
        },
        mounted() {
            console.log(this.$store.state.rugbyMatchDetailResponse.data);
            this.getHistory();
        },
        serverPrefetch() {
            return this.getHistory()
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, true).name,
                '%AWAY_TEAM%': this.$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, false).name,
                '%DATE%' : this.$route.params.date,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_history_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_history_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.rugby_match_history_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        methods: {
            getHistory() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$RUGBY_MATCH_DETAIL_H2H_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        if (response.data) {
                            this.h2h = response.data.h2h || [];
                        }
                        this.is_data_ready = true;
                    })
                    .catch(error => console.log(error))
            },
        }
    };
</script>
